
export const EventBigProducts = [
    "Curved Tent",
    "Curved Skyline",
    "20m Multiflex Tent",
    "20m Skyline Tent",
    "15m Multiflex Tent",
    "15m Skyline Tent",
    "12m Multiflex Tent",
    "12m Skyline Tent",
    "Alpine Tent",
    "Pagola Tent",
    "Exhibition Booths",
]

export const EventOtherProducts = [
    "Aladdin Tent",
    "Flooring and Carpeting",
    "Carpeting",
    "Decoration",
    "Mobile Toilets",
    "Urinal",
    "Rectangular Tables",
    "Round Tables",
    "Executive Chairs",
    "Plastic Chairs",
    "Chair Covers",
    "Table Linen",
    "Other",
    "Transport",
    "Labour",
    "VAT"
]



export const EventProducts = [
    ...EventBigProducts,
    ...EventOtherProducts
]

export const DimensionedProducts = [
    ...EventBigProducts,
    EventOtherProducts[0]
]

export const RetailEventCategories = [
    'wedding',
    'party',
    'introduction',
    'funeral',
    'other'
]

export const VATEventCategories = [
    'conference',
    'seminar',
    'corporate',
    'workshop',
    'graduation',
    "government"
]

export const EventCategories = [...RetailEventCategories, ...VATEventCategories].sort()

export const EventClassifications = ["retail", "vat"];

export const EventColours = [
    "green",
    "yellow",
    "orange",
    "teal",
    "purple",
    "dark-blue",
    "sky-blue",
    "gray",
    "red",
    "pink",
    "indigo"
]

export const EventColourMaping = {
    "green": ['bg-success text-white border-success'],
    "yellow": ['bg-warning text-white border-warning'],
    "orange": ['bg-orange text-white border-orange'],
    "teal": ['bg-teal text-white border-teal'],
    "purple": ['bg-purple text-white border-purple'],
    "dark-blue": ['bg-primary text-white border-primary'],
    "sky-blue": ['bg-info text-white border-info'],
    "gray": ['bg-secondary text-white border-secondary'],
    "red": ['bg-danger text-white border-danger'],
    "pink": ['bg-pink text-white border-pink'],
    "indigo": ['bg-indigo text-white border-indigo'],
    "brown" : ['bg-brown text-white border-brown']
}

export const firmRoles = [
    "admin",
    "finance"
]

export const LabourActivities = [
    "assembly",
    "disassembly"
]

export const expenseCategories = [
    "bank-charges",
    "capital-investments",
    "cleaning",
    "commissions",
    "communication-and-airtime",
    "consultations",
    "detergents",
    "donations",
    "education-and-training",
    "electricity",
    "entebbe-katale-komamboga",
    "food",
    "fuel",
    "gifts-and-donations",
    "insurance",
    "internet",
    "IT-services",
    "medical",
    "miscellaneous",
    "naziba-project",
    "personal",
    "office",
    "other",
    "product-hire",
    "promotions",
    "refund",
    "renovations",
    "rent",
    "repairs",
    "stationary",
    "statutory-dues",
    "taxes",
    "tent-repairs",
    "toilet-cleaning",
    "training-and-seminars",
    "transport-facilitation",
    "utilities",
    "vehicle-rentals",
    "vehicle-repairs",
    "vehicle-service",
    "water",
    "VAT",
    "NSSF",
    "PAYEE",
    "income-tax",
    "debt-repayment"
]

export const nonChoiceExpenseCategories = [
    "allowances",
    "advances",
    "wages-salaries",
]

export const allExpenseCategories = [
    ...expenseCategories,
    ...nonChoiceExpenseCategories
];

export const expenseStatuses = [
    "non-billable",
    "unbilled",
    "invoiced"
];

export const paymentMethods = [
    "bank-transfer",
    "cash",
    "cheque",
    "credit-card",
    "direct-debit",
    "mobile-payment",
    "paypal",
    "other"
]

/**
 * @type {{
 *  title: string
 *  isManual: boolean
 *  definedRates: boolean
 *  requiresDuration: boolean
 *  doOvertime: boolean
 *  expenseCategory: string
 * }[]} 
 */
export const payrollTypes = {
    "event-labour": {
        isManual: false,
        title: "Tent Labour",
        definedRates: false,
        requiresDuration: false,
        doOvertime: true,
        expenseCategory: "wages-salaries"
    },
    "washing-labour": {
        isManual: true,
        requiresDuration: true,
        definedRates: true,
        title: "Washing Wages",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    "driver-labour": {
        isManual: true,
        requiresDuration: true,
        definedRates: true,
        title: "Driver Wages",
        doOvertime: true,
        expenseCategory: "wages-salaries"
    },
    salary: {
        isManual: true,
        requiresDuration: false,
        definedRates: true,
        title: "Monthly Salaries",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    allowance: {
        isManual: true,
        requiresDuration: false,
        definedRates: false,
        title: "Allowances",
        doOvertime: false,
        expenseCategory: "allowances"
    },
    advance: {
        isManual: true,
        requiresDuration: false,
        definedRates: false,
        title: "Advances",
        doOvertime: false,
        expenseCategory: "advances"
    },
}


export const payrollPaymentStatus = [
    "open", "paritally-processed", "processed", "paid"
]

export const financialAccounts = [
    "DFCU",
    "BoA",
    "stanbic",
    "centenary-10",
    "cash",
    "mm-PK",
    "mm-WK",
]

export const AccountTypes = [
    "cash",
    "bank",
    "mobile-money",
    "fuel-card",
    "other"
]

export const assortedPaymentMethods = {
    "bank-transfer": ["bank", "mobile-money"],
    "cash": ["cash"],
    "cheque": ["bank"],
    "credit-card": ["bank"],
    "direct-debit": ["bank"],
    "mobile-payment": ["bank", "mobile-money"],
    "other": ["other"]
}



export const eventStatuses = [
    "unconfirmed",
    "confirmed",
    "cancelled"
]

export const staffTyoes = [
    "casual",
    "driver",
    "office"
]

export const taxes = [
    { title: "VAT", value: 18 }
]

export const ServiceParts = [
    "AC system",
    "Battery",
    "Alternator",
    "Belts",
    "Body/Chasis",
    "Brake Fluid",
    "Brakes (Front)",
    "Brakes (Back)",
    "Cabin Air Filter",
    "Car Cleaning",
    "Car Wash",
    "Car Waxing",
    "Tires",
    "Clutch Hyraudlic Fluid",
    "Coolant",
    "Cooling System",
    "Differntial Fluid",
    "Doors",
    "Engine Air Filter",
    "Engine Antifreeze",
    "Engine Oil",
    "Exhaust System",
    "Fuel Filter",
    "Fuel Lines & Pipes",
    "Fuel Pump",
    "Fuel System",
    "Glass/Mirrrors",
    "Heating System",
    "Horn",
    "General Inspection",
    "Cabin Lights",
    "Head Lights",
    "Oil Filter",
    "Other",
    "Power Steering Fluid",
    "Radiator",
    "Rear Window Wiper",
    "Windshield Wipers",
    "Tire Rotation",
    "Safety Devices",
    "Steering Rack",
    "Steering System",
    "Suspension System",
    "Tire Pressure",
    "Transmission Fluid",
    "Wheel Alignment",
    "Windshield Washer Fluid",
    "Rubber Bushes",
];
